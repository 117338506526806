<template>
  <div class="foot">
    <div class="content">
      <div class="top hidden-sm-and-down">
        <div class="left">
          <ul class="first">
            <li @click="toPage('/about')">关于我们</li>
            <li @click="toPage('/case')">工程案例</li>
            <li @click="toPage('/talent')">人才招聘</li>
            <li @click="toPage('/news')">新闻动态</li>
            <li @click="toPage('/contact')">联系我们</li>
          </ul>
          <ul class="secund">
            <li @click="toPage('/about',0)">公司简介</li>
            <li @click="toPage('/case')">工程案例</li>
            <li @click="toPage('/talent')">人才招聘</li>
            <li @click="toPage('/news',0)">公司动态</li>
            <li @click="toPage('/contact')">联系我们</li>
          </ul>
          <ul class="third">
            <li @click="toPage('/about',1)">企业文化</li>
            <li></li>
            <li></li>
            <li @click="toPage('/news',1)">行业资讯</li>
            <li></li>
          </ul>
          <ul class="third">
            <li @click="toPage('/about',2)">企业资质</li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div class="right">
          <p class="first">联系方式</p>
          <p class="secund" style="margin: 10px 0">电话：400-1009111</p>
          <p class="third" style="margin: 10px 0">邮箱：ZJKY20210324@163.com</p>
        </div>
      </div>
      <div class="bottom">
        <p>
          CopyRight © 2021 科艺发展 ALL RIGHT RESRVED 冀ICP备20007784号-2
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  methods: {
    toPage(url, type) {
      if (type !== undefined) {
        this.$router.push({
          path: url, query: {
            type: type
          }
        });
      } else {
        this.$router.push({
          path: url, query: {}
        });
      }
      setTimeout(() => {
        this.$router.go(0);
      }, 100)
    },
  }
};
</script>

<style lang="scss" scoped>
.foot {
  width: 100%;
  background: #333333;

  .content {
    width: 68%;
    height: 100%;
    margin-left: 16%;

    .top {
      width: 100%;
      border-bottom: 1px solid #ffffff;
      padding-bottom: 15px;
      overflow: hidden;

      .left, .right {
        float: left;
      }

      .left {
        width: 70%;
        height: 100%;

        .first {
          display: flex;
          margin-top: 30px;

          li {
            flex: 1;
            text-align: center;
            list-style: none;
            font-size: 16px;
            color: #ffffff;
            cursor: pointer;
          }
        }

        .third, .secund {
          display: flex;

          li {
            flex: 1;
            text-align: center;
            list-style: none;
            font-size: 12px;
            color: #999999;
            cursor: pointer;
          }
        }
      }

      .right {
        width: 30%;
        height: 100%;
        padding-left: 30px;

        .first {
          margin-top: 30px;
          margin-left: 30px;
          font-size: 16px;
          color: #ffffff;
          font-weight: bold;
        }

        .secund, .third {
          font-size: 12px;
          color: #ffffff;
        }
      }
    }

    .bottom {
      width: 100%;
      text-align: center;
      padding-top: 15px;
      //padding-bottom: 15px;
      overflow: hidden;

      p {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
