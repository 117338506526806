<template>
  <div class="views-index">
    <div class="hander">
      <b-navbar toggleable="lg">
        <b-navbar-brand href="#">
          <div class="views-title">
            <img src="../assets/logo_new.png" alt="">
          </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="nav-group">
            <router-link style="padding: 15px;" v-for="(item,i) in handList" :key="i" :to="item.path">{{ item.name }}
            </router-link>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <div class="wchart-img" v-if="pageData">
      <div>
        <img src="../assets/image/official.png" alt="">
        <div class="showView" v-if="pageData.globAds[0]">
          <img :src="serverUrl + pageData.globAds[0].savePath" alt="">
        </div>
      </div>
      <div>
        <img src="../assets/image/app.png" alt="">
        <div class="showView" v-if="pageData.globAds[1]">
          <img :src="serverUrl + pageData.globAds[1].savePath" alt="">
        </div>
      </div>
      <div>
        <img src="../assets/image/callUs.png" alt="">
        <div class="showView" v-if="pageData.globAds[2]">
          <img :src="serverUrl + pageData.globAds[2].savePath" alt="">
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view :key="key" />
    </keep-alive>
    <Bottom/>
  </div>
</template>

<script>
import {getCats} from "@/api/api"
import {serverUrl} from "@/utils/config";
import Bottom from "./components/bottom.vue"

export default {
  name: "Index",
  components: {
    Bottom
  },
  data() {
    return {
      cachedViews: ["/news"],
      handList: [
        {name: '首页', path: '/home', id: '1'},
        {name: '关于我们', path: '/about', id: '2'},
        {name: '工程案例', path: '/case', id: '3'},
        {name: '人才招聘', path: '/talent', id: '4'},
        {name: '新闻动态', path: '/news', id: '5'},
        {name: '招标公告', path: '/news2', id: '6'},
        {name: '联系我们', path: '/contact', id: '7'},
      ],
      serverUrl: serverUrl,
      pageData: null
    }
  },
  computed: {
    key() {
      this.setData(this.$route.path)
      console.log(this.$route.path)
      // this.cachedViews.push("/1")
      // this.cachedViews.push(this.$route.path)
      return this.$route.path
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    getPageData() {
      getCats().then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageData = data;
        }
      })
    },
    setData(path) {
      this.cachedViews.push(path)
    }
  }
}
</script>

<style lang="scss">
.views-index {
  width: 100%;
  height: 100%;
}

a:-webkit-any-link {
  color: #393133;
  margin-left: 15px;
  font-size: 22px;
}

a {
  display: inline-block;
  text-align: center;
  color: #393133;
  margin-left: 15px;
  font-size: 22px;
}

a.router-link-exact-active {
  color: #0D9484;
  text-decoration: underline;
  border-bottom: 2px solid #0D9484;
}

.hander {
  width: 100%;
  padding-top: 15px;
  font-weight: bold;
  overflow: hidden;

  .navbar {
    padding: 0;
  }

  .views-title {
    margin-left: 50px;

    img {
      height: 100px;
    }

  }

  .nav-group {
    overflow: hidden;
    margin-left: 50px;
    margin-top: 50px;
  }
}


.wchart-img {
  z-index: 999;
  position: fixed;
  right: 0;
  top: 380px;

  div {
    margin-right: 10px;
    position: relative;

    &:hover {
      .showView {
        display: block;
      }
    }
  }

  img {
    margin-top: 5px;
    width: 80px;
    height: 80px;
  }

  .showView {
    position: absolute;
    left: -130px;
    top: 0;
    display: none;
    width: 120px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}


@media screen and (max-width: 768px) {
  .hander {
    .navbar {
      padding: 0 3% 0 0;
    }

    .views-title {
      margin: 0;

      img {
        height: 45px;
        position: relative;
        top: -10px;
      }
    }

    .nav-group {
      margin-left: 0px;
    }

  }
}
</style>
